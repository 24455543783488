import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="私たちについて"
      pagedesc="中小企業向け採用サイト制作専門のイチモンについてのご紹介です。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <div className="p-content">
        <div className="m-mv mv">
          <div className="mv__img-wrap">
            <Img fluid={data.key01.childImageSharp.fluid} alt="メインビジュアル画像" style={{ height: "100%", width: "100%" }} />
            <h2 className="mv__hdg">私たちについて</h2>
          </div>
        </div>
        <div className="p-head head l-wrap">
          <h3 className="head__hdg">「採用活動を無くす」</h3>
          <h4 className="head__desc">中小企業から採用活動を無くす<br className="u-sp"/>あなたはあなたのやるべきことを</h4>
        </div>

        <div className="p-representation representation l-wrap">
          <ul className="representation__list">
            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">01</span>
                <span className="deno">&#x2f;04</span>
              </div>
              <div className="representation__body">
                <h3 className="representation__hdg">
                  中小企業向け、採用サイト専門制作チーム
                </h3>
                <p className="representation__desc">
                  私たちは、中小企業を対象とした採用サイト専門の制作チームです。<br/>
                  「カネがない」「人手がかけられない」「ノウハウがない」「知名度がない」ことが多い中小企業の採用活動は困難が伴います。<br/>
                  採用活動で成果を出すには、正しい戦略をもとに運用し続ける必要があります。<br/>
                  サイト制作のみならず、その後の運用を含めてお手伝いすることに注力するため、採用サイト専門の制作チームという形をとりました。
                </p>
              </div>
            </li>

            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">02</span>
                <span className="deno">&#x2f;04</span>
              </div>

              <div className="representation__body">
                <h3 className="representation__hdg">
                  長期間のお付き合いを前提にお考えください
                </h3>
                <p className="representation__desc">
                  採用サイトはあくまで求職者が知りたい情報を発信する「箱」にすぎません。<br/>
                  求職者にとって有益な情報を発信し、優秀な人材を採用するためには『採用サイト運用』が欠かせません。<br/>
                  採用サイトを作って終わりではなく、ともによりよい採用活動にするために努力しましょう。
                </p>
              </div>
            </li>

            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">03</span>
                <span className="deno">&#x2f;04</span>
              </div>

              <div className="representation__body">
                <h3 className="representation__hdg">
                  採用活動から始まる「組織改革」を支援
                </h3>
                <p className="representation__desc">
                  働き方改革、生産年齢人口の現状など、組織運営が大きく変わっています。<br/>
                  「何から始めればいいのかわからない」と組織改革に二の足を踏んでいると、求職者にとって魅力的な企業ではなくなってしまいます。<br/>
                  採用活動を契機として、「従業員がこの会社で働くことに満足できる仕組み」作りに取り組みませんか？
                </p>
              </div>
            </li>

            <li className="representation__item">
              <div className="representation__num">
                <span className="nume">04</span>
                <span className="deno">&#x2f;04</span>
              </div>

              <div className="representation__body">
                <h3 className="representation__hdg">
                  私たちが目指すのは「採用担当部署」
                </h3>
                <p className="representation__desc">
                  採用にまつわる様々な活動をお手伝いできます。<br/>
                  ただのウェブサイト制作だけではなく、企業の「採用担当チーム」とお考えください。
                </p>
              </div>
            </li>
          </ul>
        </div>


      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "about_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`